import {
  Deal, PixelType, StrategyType, TrackingPixel
} from "./deal";
import {
  Paper,
  Grid, Typography, Button
} from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import dayjs from "dayjs";
import UserControlTargeting from "./userControlTargeting";
import SupplyTargeting from "./supplyTargeting";
import BasicDealDetails, { ViewabilityTags, DealType} from "./basicDealDetails";
import DeviceDataTargeting from "./deviceDataTargeting";
import GeographicTargeting from "./geographicTargeting";
import AddSupply from "./addSupply";
import AddDemand from "./addDemand";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarNotificationsContext } from '@dtx/ui-core/src/context/SnackbarNotificationsProvider';
import {useUserSession} from "../../hooks/auth/hooks";
import { AsyncStore } from "@dtx/ui-core/src/query";

interface DealFormProps {
  deal: Deal | null
  editing: boolean
  resolvedStrategies: string[]
  customMarginError: string|null
  store: AsyncStore
}

export const DealForm = (props:DealFormProps) => {
  const {deal} = props
  const location = useLocation()
  const dealToEdit = location.state?.deal || null;
  const refreshKey = location.state?.refreshKey || null;
  const { showNotification } = useContext(SnackbarNotificationsContext)
  const [hideSubmitButton,setHideSubmitButton] = useState(false);
  // @ts-ignore
  const defaultDealValues: Deal = {
    id: "",
    demand: {
      networks: [] // Directly setting an empty array as the default for networks
    },
    "deal-id": '',
    "deal-description": '',
    "from-date": new Date().toISOString(),
    "to-date": new Date().toISOString(),
    "bid-floor-price": 0.01,
    "pricing-adjustment": 0,
    "deal-floor-price-strategy": 0,
    "auction-type": 0,
    active: false,
    "conditions-json": {
      geo: { strategy: StrategyType.include, value: [] },
      publishers: { strategy: StrategyType.include, value: [] },
      contents: { strategy: StrategyType.include, value: [] },
      categories: { strategy: StrategyType.include, value: [] },
      blockedCategories: { strategy: StrategyType.include, value: [] },
      domainOrBundle: { strategy: StrategyType.include, value: [] },
      os: { strategy: StrategyType.include, value: [] },
      language: { strategy: StrategyType.include, value: [] },
      muted: { strategy: StrategyType.include, value: "all" },
      hasIFA: { strategy: StrategyType.include, value: "all" },
      dnt: { strategy: StrategyType.include, value: "all" },
      deviceType: { strategy: StrategyType.include, value: "all"},
      adSizes: { strategy: StrategyType.include, value: [] },
      skippableTraffic: { strategy: StrategyType.include, value: "all" },
      omSupported: { strategy: StrategyType.include, value:"all" },
      displayType: { strategy: StrategyType.include, value: "all" },
      rewarded: { strategy: StrategyType.include, value: "all" },
      deviceOS: { strategy: StrategyType.include, value: "all" },
      createdBy: useUserSession().user.email,
    },
    "is-public": true,
    "deal-type": DealType["Preferred Deal"],
    "created-by": parseInt(useUserSession().user.id,10 ),
    "viewability-tag": ViewabilityTags["Exclude Viewability Tracking"],
    "fees-json": {
      rebate: 0,
      resellerFee: 0,
      dspFee: 0,
      techFee: 0,
      postAuctionDiscount: 0,
      other: 0,
      baselineMargin: 27,
      additionalMargin: 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "",
    state: "",
    "tracking-pixels": {
      "current-pixel-type": "",
      "current-pixel-url": "",
      "tracking-pixels": []
    }
  };

  const navigate = useNavigate()
  const methods = useForm<DealFormProps>({
    mode: 'onChange',
    defaultValues: {
      deal: dealToEdit || defaultDealValues,
      editing: dealToEdit === null,
      resolvedStrategies: [],
      customMarginError: null,
    }
  })
  const { control,reset, getValues, handleSubmit, setValue, watch, trigger , formState: { errors }  } = methods

  useEffect(() => {
    if (dealToEdit) {
      reset({
        deal: dealToEdit
      });
    }
  }, [dealToEdit, reset]);

  function convertGeo (deal: Deal) {
      const convertedDeals =deal["conditions-json"].geo.value ?  deal["conditions-json"].geo.value.map((value) => {
        let emptyGeo = {
          region: "",
          metro: "",
          city: "",
          zipCode: "",
          worldRegion: "",
          country: ""
        }
        const cityRegion = value["sub-2-iso"] ? value["sub-2-iso"] : value["sub-1-iso"]

        switch(value["result-type"]) {
          case "worldRegion":
            emptyGeo.worldRegion = value["display-name"].split(" (")[0]
            break
          case "country":
            emptyGeo.country = value["country-iso-code"]
            break
          case "region":
          case "state":
            emptyGeo.country = value["country-iso-code"]
            emptyGeo.region = value["sub-1-iso"]
            break
          case "metro":
            emptyGeo.country = value["country-iso-code"]
            emptyGeo.region = value["sub-1-iso"]
            emptyGeo.metro = value["metro-code"]
            break
          case "postal_code" :
            emptyGeo.zipCode = value["display-name"].split("-")[0].trim()
            emptyGeo.country = value["country-iso-code"]
            emptyGeo.region = cityRegion
            emptyGeo.city = value["city-name"]
            break
          case "city":
            emptyGeo.country = value["country-iso-code"]
            emptyGeo.region = cityRegion
            emptyGeo.metro = value["metro-code"]
            emptyGeo.city = value["city-name"]
            break
        }
        return emptyGeo
      }) : null
    return convertedDeals

  }

  const flatteningData = (data, fieldName) => {
    return data ? data.map((value) => value[fieldName].toString()) : null
  }
  const  onSubmit = async data => {
    setHideSubmitButton(true);
    if (data.deal["private-auction"]){
      data.deal["is-public"] = false
    }
    const dealBEModel = JSON.parse(JSON.stringify(data.deal))
    const publishersId = flatteningData(data.deal["conditions-json"].publishers.value,"publisher-id")
    const unitIds =  flatteningData(data.deal["conditions-json"].contents.value,"unit-id")
    const categories = flatteningData(data.deal["conditions-json"].categories.value,"category-name")
    const blockedCategories = flatteningData(data.deal["conditions-json"].blockedCategories.value,"category-name")
    dealBEModel["conditions-json"].contents.strategy = data.deal["conditions-json"].publishers.strategy
    dealBEModel["conditions-json"].contents.value = unitIds
    dealBEModel["conditions-json"].publishers.value = publishersId
    dealBEModel["conditions-json"].geo.value = convertGeo(data.deal)
    dealBEModel["conditions-json"].categories.value = categories
    dealBEModel["conditions-json"].blockedCategories.value = blockedCategories
    dealBEModel["tracking-pixels"]["current-pixel-type"] = 0
    const userEmail = useUserSession().user.email;
    const response = await fetch(`/api/deals/save-deal`,
    {
      headers: {
        'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({ deal: dealBEModel, email: userEmail }),
    })
    if(response.ok) {
      showNotification("success", "Deal saved successfully")
      await props.store.invalidateQuery(refreshKey)
    }
    else{
      showNotification("error", "Failed to save deal")
    }
    if(dealBEModel["deal-id"] === "") {
      const id = await response.json()
      data.deal.id = id.toString()
      data.deal["deal-id"] = "DTX" + id.toString()
      navigate(`/deals/edit/${id}`, { state: { deal: data.deal } })
    }
    setHideSubmitButton(false);
  };

  const formDealId = watch("deal.deal-id")
  const formDealIsPublic = watch("deal.is-public")
  const formAuctionType = watch("deal.auction-type");
  const formPricingModel = watch("deal.pricing-type");
  const formPriceStrategy = watch("deal.deal-floor-price-strategy")
  const fromDateValue = watch("deal.from-date");
  const strategyGeoValue = watch('deal.conditions-json.geo.strategy');
  const strategyLanguagesValue = watch('deal.conditions-json.language.strategy');
  const strategyPublisherValue = watch('deal.conditions-json.publishers.strategy');
  const strategyCategoryValue = watch('deal.conditions-json.categories.strategy');
  const strategyDomainsOrBundlesValue = watch('deal.conditions-json.domainOrBundle.strategy');
  const strategyBlockedCategoryValue = watch('deal.conditions-json.blockedCategories.strategy');
  const selectedGeoSearchItems = watch("deal.conditions-json.geo.value") || [];
  const selectedPublishersSearchItems = watch("deal.conditions-json.publishers.value") || [];
  const selectedContentsSearchItems = watch("deal.conditions-json.contents.value") || [];
  const selectedCategories1 = watch("deal.conditions-json.categories.value") || [];
  const selectedDomainsOrBundles = watch("deal.conditions-json.domainOrBundle.value") || [];
  const selectedBlockedCategories = watch("deal.conditions-json.blockedCategories.value") || [];
  const selectedLanguages = watch("deal.conditions-json.language.value") || [];
  const selectedDemands = watch("deal.demand.networks") || [];
  const fees = watch("deal.fees-json");
  const formPricingAdjustment = watch("deal.pricing-adjustment");
  const formTrackingPixels = watch("deal.tracking-pixels");


  useEffect(() => {
    const currentToDate = watch("deal.to-date");
    if (dayjs(currentToDate).isBefore(dayjs(fromDateValue), 'day')) {
      setValue("deal.to-date", fromDateValue);
    }
  }, [fromDateValue, setValue, watch]);
  return (
    <Paper elevation={4} style={{padding: 16, backgroundColor: "rgba(250, 250, 250, 0.9)", margin: 96}} >
      <Typography  variant="h5">{formDealId === "" ? "Create a new deal" : "Edit deal" }</Typography>
      <Grid container style={{marginTop: 24}}>
      <Grid item xs={10} >
        <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BasicDealDetails control={control} getValues={getValues} formTrackingPixels={formTrackingPixels} formPricingModel={formPricingModel} formDealIsPublic={formDealIsPublic} formPriceStrategy={formPriceStrategy} formDealId={formDealId}  fromDateValue={fromDateValue} setValue ={setValue} formAuctionType={formAuctionType} formPricingAdjustment = {formPricingAdjustment}  fees = {fees}/>
          <GeographicTargeting control={control} setValue={setValue} getValues={getValues} selectedGeoSearchItems={selectedGeoSearchItems} strategyGeoValue={strategyGeoValue} showNotification={showNotification}/>
          <DeviceDataTargeting control={control} selectedLanguages={selectedLanguages} setValue={setValue} getValues={getValues} strategyLanguagesValue={strategyLanguagesValue} />
          <UserControlTargeting control={control} />
          <SupplyTargeting control={control} />
          <AddSupply control={control} setValue={setValue} getValues={getValues} selectedPublishersSearchItems={selectedPublishersSearchItems} selectedContentsSearchItems ={selectedContentsSearchItems}
                     strategyPublisherValue={strategyPublisherValue} strategyCategoryValue={strategyCategoryValue} selectedCategories={selectedCategories1}
                     strategyBlockedCategoryValue={strategyBlockedCategoryValue} selectedBlockedCategories={selectedBlockedCategories}
                     strategyDomainsOrBundlesValue={strategyDomainsOrBundlesValue} selectedDomainsOrBundles={selectedDomainsOrBundles} />
          <AddDemand control={control} setValue={setValue} getValues={getValues} selectedDemands={ selectedDemands}/>
          <div style={{display: "flex", alignItems:"center", flexDirection:"column", margin: 48}}>
            <Button type="submit" variant="contained" color="primary" style= {{padding: "24px"}} disabled={hideSubmitButton}>
              Submit
            </Button>
          </div>
        </form>
        </FormProvider>
      </Grid>
    </Grid>
    </Paper>
  )
}
