import MoreVert from "@mui/icons-material/MoreVert";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useUserSession } from "../../hooks/auth/hooks";

export interface DealsActionsCellProps {
  row: any;
  actionsNames: string[];
  callbackActions: any;
  onUpdate:  () => void;
}

export function DealsActionsCell(props: DealsActionsCellProps) {
  const { row, actionsNames, callbackActions, onUpdate } = props;

  // Menu
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string>(''); // Track selected action

  const handleActionClick = (actionName: string) => {
    if (actionName === 'Activate Deal' || actionName === 'Stop Deal') {
      setSelectedAction(actionName); // Set the selected action
      setMenuAnchor(null);
      setConfirmationDialogOpen(true);
    } else {
      callbackActions[actionsNames.indexOf(actionName)](row);
      setMenuAnchor(null);
    }
  };

  const sendStatusUpdate = async (dealId: string, isActive: boolean,email: string) => {
    try {

      const response = await fetch(`/api/deals-aux/update-active-deal`, { // Adjust the endpoint URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json' // Corrected syntax
        },
        mode: "cors", // CORS mode is outside the headers
        body: JSON.stringify({
          id: dealId,
          active: isActive,
          email: email,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedDeal = await response.json();
      await onUpdate();
    } catch (error) {
      console.error("Failed to update deal status:", error);
      // Handle the error in your UI as well
    }
  };


  const handleConfirmationDialogClose = (confirmed: boolean) => {
    setConfirmationDialogOpen(false);

    if (!confirmed || !selectedAction) {
      setSelectedAction(''); // Reset selected action
      return;
    }
    const isActive = selectedAction === 'Activate Deal';
    const email = useUserSession().user.email || "LocalHost";
    sendStatusUpdate(row.id, isActive, email).catch((error) => { console.error("Failed to update deal status:", error); });
    setSelectedAction(''); // Reset selected action
  };

  return (
    <>
      <IconButton
        id="action-button"
        aria-haspopup="true"
        aria-controls={Boolean(menuAnchor) ? 'action-menu' : undefined}
        aria-expanded={Boolean(menuAnchor)}
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          setMenuAnchor(event.currentTarget);
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="action-menu"
        MenuListProps={{
          'aria-labelledby': 'action-button',
        }}
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onBackdropClick={(event) => event.stopPropagation()}
        onClose={() => setMenuAnchor(null)}
      >
        {actionsNames.map((item) => (
          <MenuItem
            key={item}
            onClick={(event) => {
              event.stopPropagation();
              handleActionClick(item); // Pass the action name to the click handler
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationDialogOpen} onClose={() => handleConfirmationDialogClose(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to {selectedAction === 'Activate Deal' ? 'activate this deal' : selectedAction === 'Stop Deal' ? 'stop this deal' : ''}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmationDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmationDialogClose(true)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
