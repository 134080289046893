import * as React from 'react';
import TextField from '@mui/material/TextField';
import {
  IconButton,
  ListItemSecondaryAction, Paper,

} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useState, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import CheckIcon from '@mui/icons-material/Check';

interface editableRowProps {
  stringResult: string
  setResults: (results: any) => void
  handleBlur: (e: string, index: number) => void
  index: number
}

export default function editableRow(props: editableRowProps) {
  const { stringResult, handleBlur, setResults,index } = props
    const [stringValue, setStringValue] = useState(stringResult)
    const [isEditing, setIsEditing] = useState(false)

    const handleEdit = () => {
      setIsEditing(true)
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setStringValue(e.target.value)
    };


    return (
      <ListItem key={index} component="div" disablePadding>
        <ListItemButton>
          {isEditing ? (
            <div>
              <TextField
                value={stringValue}
                onChange={handleChange}
                onBlur={(event) => {
                  handleBlur(event.target.value, index)
                  setIsEditing(false)
                }}
                autoFocus
                style={{ color: 'red' }}
              />
              <IconButton
                color={"success"}
                edge="end"
                aria-label="edit"
                onBlur={(event) => {
                  handleBlur(event.target.value, index)
                  setIsEditing(false)
                }}              >
                <CheckIcon />
              </IconButton>
            </div>
          ) : (
            <ListItemText
              primary={stringValue}
              onClick={handleEdit}
              style={{ color: 'red', cursor: 'pointer' }}
            />
          )}
          <ListItemSecondaryAction>
            <IconButton
              color={"error"}
              edge="end"
              aria-label="delete"
              onClick={() =>
                setResults((prevResults) =>
                  prevResults.filter((_, i) => i !== index)
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    )
  }

